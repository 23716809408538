export default {
	cinema: {
		address: 'ул. Ленина, д. 17',
		place: null,
		phoneNumbers: ['8 (999) 735-09-18', '8 (48456) 5-69-22'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kinokirov40',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://itunes.apple.com/ru/app/кинозал-юбилейный-билеты-в-кино/id1255091182?mt=8',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.jubilee',
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CBQdb4b88D',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
