import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '80, 114, 153',
		'primary-dark': '48, 80, 117',
		'accent': '255, 226, 0',
		'accent-plus': '0, 0, 0',
	},
});
